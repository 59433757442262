import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`We have a multitude of sites using the gatsby theme for both public and internal sites. Using this theme we’ve been able to propogate features and fixes throughout the ecosystem quickly and easily.`}</p>
    </PageDescription>
    <h2>{`IBM Design for AI`}</h2>
    <FeatureCard title="IBM Design for AI" actionIcon="arrowRight" href="https://ibm.com/design/ai/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.388888888888886%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABGklEQVQoz2NgYGD4jwszMTH9FxDg/y8iLPyfh4cXLs7IyPifn5//PxsbG4oYlI3bQDgGKkbSAGaDLEMWg2OQBC6D2NjZ/3tYG/6XFhdBEUc3HE0fbgWykuL/D00v+x/ubAYNAkZchqAaiI4ZwU5n/m+opvB/e038/3RPC4Q4sQaCFDEzM6MoFhYU+O9hqPZfWVriPyNQDjmykNWxsLDgjxQ2Ntb/mWmx/40M9P7rKcv+FxUWIhRu2MOQGeoKZyfb/5fP7f2fnhzzX1VF+b+mpjrcIEYsMY4zDGExnpUZ/3/t8hn/p01s/Z+bn/k/Ji4KnubQDcSSSlCdDHJlc0PJ//kzOv831RX/r6gu+V9aXvRfSloKxVXoBsMwAGfw514LifnNAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Design AI",
          "title": "IBM Design AI",
          "src": "/static/b43779274724accdfeaa53284ce6f45f/3cbba/ai.png",
          "srcSet": ["/static/b43779274724accdfeaa53284ce6f45f/7fc1e/ai.png 288w", "/static/b43779274724accdfeaa53284ce6f45f/a5df1/ai.png 576w", "/static/b43779274724accdfeaa53284ce6f45f/3cbba/ai.png 1152w", "/static/b43779274724accdfeaa53284ce6f45f/0b124/ai.png 1728w", "/static/b43779274724accdfeaa53284ce6f45f/e4a3d/ai.png 2003w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`IBM Design Language`}</h2>
    <FeatureCard title="IBM Design Language" actionIcon="arrowRight" href="https://www.ibm.com/design/language/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.041666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHm48yQgf/EABkQAAIDAQAAAAAAAAAAAAAAAAEQAAIRMf/aAAgBAQABBQLESjLd/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBECFB/9oACAEBAAE/IUROCJrqn//aAAwDAQACAAMAAAAQgy//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QjX//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAGxABAAICAwAAAAAAAAAAAAAAAQAxIbFBcaH/2gAIAQEAAT8QeF7APFzGiQqa2XdGp//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Design Language",
          "title": "IBM Design Language",
          "src": "/static/0150ee5adf1d7a00affcc60be9a4aeb5/2e753/idl.jpg",
          "srcSet": ["/static/0150ee5adf1d7a00affcc60be9a4aeb5/69549/idl.jpg 288w", "/static/0150ee5adf1d7a00affcc60be9a4aeb5/473e3/idl.jpg 576w", "/static/0150ee5adf1d7a00affcc60be9a4aeb5/2e753/idl.jpg 1152w", "/static/0150ee5adf1d7a00affcc60be9a4aeb5/74f4b/idl.jpg 1728w", "/static/0150ee5adf1d7a00affcc60be9a4aeb5/3a72c/idl.jpg 2020w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`Carbon Design System`}</h2>
    <FeatureCard title="Carbon Design System" actionIcon="arrowRight" href="http://carbondesignsystem.com/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.041666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACWElEQVQozyWSWW/aUBCFLVVVH1ulSZWSsBkCAcIS9iXELDYYs2/GLCGQhqjKW/P/pa8DeRjde0d3zjlzZpR2q4nZNrDMFlanTc8y6Xc7DPpdJuMh89kYezbBnk9w7JmcU8nJfWGzchZsNiscx6Yj9aNhHyWo+vF7vURvwyfQ0aCH0WwQDqiU8jkadY35dMxjtcJw0BfCjtwfhGTKQgi2282J9PjnSKoE/CrHCN3cUCrkKRUL5DMZ0rEY0XCI+1TqVNwVIKtjkrm/Jyx/NQFd2FNsiefnNcul/QlYqWpUaw3S+SJXl79xXV4SCgbJpNMEVJVIOHxqTxfV2mP19Falo7toRPJznjYOr392LJ05M1GpGLpBu9WWaKHrTVq6TqVYFF9b4qXFsN87KTSazRPgMb9eLsU/h5Wo2m7XHF5fWK8dUbpFaRs6XdNkIMUjAZ70+zSzeUwhmk2lpdlxEHPy2SyxyC25TFrIDfFswrOA7QRkvV6x3+94edmj5KS1oyrTaGLpdTG9jdGoYcnkx6PjlGfilU0qEScpcQSraxrTyZj3979UKxXOvv8Q/wscDgeUfC5PNp0lGU98FsXjcibkY1nWYCCTHchqPKHV6tK2zn63O8Xb20FU7Qn5/Xz78hW3y8VqtUIJh6N43TeyOkFuQzE8bhXPtY94NM6g12Mm7TqTKZoQDa0W9XKZWrnAx8c/8VTj6ucZ7otzfNdXqGoQxX3lEXQfHpcX1RPE75E18gW5i0SwLEPWwWFkdiglVNqdBgnJP2RSdLtdkskUv84vCAZETCTOXSLFf/+oX/GfM9AEAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Carbon Design System",
          "title": "Carbon Design System",
          "src": "/static/6ba978eda7df416a48395e15099c0781/3cbba/cds.png",
          "srcSet": ["/static/6ba978eda7df416a48395e15099c0781/7fc1e/cds.png 288w", "/static/6ba978eda7df416a48395e15099c0781/a5df1/cds.png 576w", "/static/6ba978eda7df416a48395e15099c0781/3cbba/cds.png 1152w", "/static/6ba978eda7df416a48395e15099c0781/0b124/cds.png 1728w", "/static/6ba978eda7df416a48395e15099c0781/f6871/cds.png 2020w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`IBM Design Research`}</h2>
    <FeatureCard title="IBM Design Research" actionIcon="arrowRight" href="https://ibm.com/design/research/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.041666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACMElEQVQozyWSx27bUBRE+QtBkCALw4HtQHBsFVKFZnnsvUqk5G54EeT/f+HkilkQIF6Ze2bmaUPpEdgrrq6/k/kbMmdF6S7w9Bta947PMeHPc83ft56hcOnCNeH6hjF7oA3WFMGO97HgYfObi8uvaMc6pEkVXe7zMlRkak3pzOl8ndfW4/Op41AoPo85r/uEjz6gVXOayKSNTdnzODWh3Pc4lAHaIAv7wqfNFGXkoN//InpYElkr9qnNYxPRJjYfQ0YRWbS5w7FSFGo1EZ6BntpYhAPK2EF77hKG0mc5u2A+u2R29YPMmmGtrhkLSwQVexE5W9/ntggojqU9xaRkcClDXvqEKjRZ3v5EO9M1qUsttvdVirG4JbXv8Mx7+lxRC13m73gRuwcZ/NjF01ro6DSJRay2k90isqlSD60VW4Gl48u0M35sL6l8QywvxapYTB0ayWoUm6HsHXKLOtqSi+U+lhK99ZSxMhfEroF2qj1SZeBu70jcjdDdc0okv9jgpXEYS4fcW3FIDSlhw/shEgGHROnk/nqifO0jEdOJhFobRT1VG4bKp8vO/xJ0blIIZeAYVGc6yfI45ekx1oGU6E7fqQ0kFpdeXMZydi+RaH3miGV5e+FOLltEtk4rYtvtii9ze7JZx7upkKH2eRtzQqFpMymo8aVZky6VeyLYiZZW+Ut5e/8Fcwk/Fuw6MNhsl3ybm5SeNBlupnYLWX+uTIlmQS2CQ+VRSZ7dlKUxAf0DaO5eAe+TlrIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Design Research",
          "title": "IBM Design Research",
          "src": "/static/57b24fbf04cf5e826ea1ea4c3f3315f7/3cbba/idr.png",
          "srcSet": ["/static/57b24fbf04cf5e826ea1ea4c3f3315f7/7fc1e/idr.png 288w", "/static/57b24fbf04cf5e826ea1ea4c3f3315f7/a5df1/idr.png 576w", "/static/57b24fbf04cf5e826ea1ea4c3f3315f7/3cbba/idr.png 1152w", "/static/57b24fbf04cf5e826ea1ea4c3f3315f7/0b124/idr.png 1728w", "/static/57b24fbf04cf5e826ea1ea4c3f3315f7/f6871/idr.png 2020w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`IBM Design Event`}</h2>
    <FeatureCard title="IBM Design Event" actionIcon="arrowRight" href="https://ibm.com/design/event/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.041666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACyElEQVQozxWS2U9TeQCFf5JxXIgPMzETZ4xLdByXZGI0gyPGGDGiA4qINaIo0g5tLZRCqSkU7Ebb9Krctl6hpRSBUkDB2wUKxULi/mB88G/6vD6c13O+c3JEuzNHlyVCb/dTTJYUHdYpWu8l6DeEmJTGyM8u8mnjHYuvy2w61M7mP+/gMj/kq5qj8DqHNOAhEZZxO2WaG+OIQKpIs+8l3bYpumxJvJYgfptE+KFMIjTPqY4Cu66nOdaoIPbrOXjaxFpB5WvuFUpQZiwq43xeRpyS2Ha0HRGbUBmIZ3HFVbxKFskzjRwaoV8z7AtkqbGX2dGygbg8x0/nYmw52ceJejsNF22Ym+wsJOKc6cwh/vbw+3E9IppSeRxJ8+yHoTyH15tgYFAmODyHa2CUzp4Y/0mf2axb5edGlV8uphC72xA76xl0elnSpqi8lETsu8tNnUZoDL7E4M1gN6Xw98cJuEZ4PDzK2It5hoJxPNEZ/rFrhEf6+K26h1vWGa6d96CvM7GupgmlvyCu5NlaHaS2LYJQxnPc7Z1Ad0PBYZaxuSZpdczQ6c5g7E1g9GSp1GmV9rZRo3MSUGbplma4d9tP2PGEWtd7xNVltl5T2albQNx+ME9rS5RmS5ornXPcd+eptaqaFqgxz3OwtUBFU5GKqgC3DI+QlRTR0QwOf5L/fUv8Wv8CcaibszeHKK69RVgCK9h8OfTeLO3BIkZfFlOwgHkoi9mbo6pjGdFUYnvDAvtrJZr0YXpdETKzeaxPSlQc79f2rMPY4eHdWhHhT5TpkUtYpCJ9sRLOZ2sos2+RU6u4I0tYw3n+uL+KaFhB1GvVq2T2/ttFcrrEma51xIkw2/5qweZJ4g5NIwZHyjiVdRyxN4TH15lY/MjGh2+oy58Zy2wwnNQCpRXqHCsc0RepbMyy50IAg/bZw4Y32l18HKg2MJ5ZJjZe5jt8T9wSgkSKXgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Design Research",
          "title": "IBM Design Research",
          "src": "/static/95cbc7386cbcf076f85a003d8c1bda1c/3cbba/ide.png",
          "srcSet": ["/static/95cbc7386cbcf076f85a003d8c1bda1c/7fc1e/ide.png 288w", "/static/95cbc7386cbcf076f85a003d8c1bda1c/a5df1/ide.png 576w", "/static/95cbc7386cbcf076f85a003d8c1bda1c/3cbba/ide.png 1152w", "/static/95cbc7386cbcf076f85a003d8c1bda1c/0b124/ide.png 1728w", "/static/95cbc7386cbcf076f85a003d8c1bda1c/be8bf/ide.png 2018w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      